// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advisors-index-js": () => import("./../../../src/pages/advisors/index.js" /* webpackChunkName: "component---src-pages-advisors-index-js" */),
  "component---src-pages-alumni-index-js": () => import("./../../../src/pages/alumni/index.js" /* webpackChunkName: "component---src-pages-alumni-index-js" */),
  "component---src-pages-application-index-js": () => import("./../../../src/pages/application/index.js" /* webpackChunkName: "component---src-pages-application-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-unsubscribe-index-js": () => import("./../../../src/pages/unsubscribe/index.js" /* webpackChunkName: "component---src-pages-unsubscribe-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-alumni-post-js": () => import("./../../../src/templates/alumni-post.js" /* webpackChunkName: "component---src-templates-alumni-post-js" */),
  "component---src-templates-bildungsgutschein-page-js": () => import("./../../../src/templates/bildungsgutschein-page.js" /* webpackChunkName: "component---src-templates-bildungsgutschein-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-campus-page-js": () => import("./../../../src/templates/campus-page.js" /* webpackChunkName: "component---src-templates-campus-page-js" */),
  "component---src-templates-career-page-js": () => import("./../../../src/templates/career-page.js" /* webpackChunkName: "component---src-templates-career-page-js" */),
  "component---src-templates-course-page-js": () => import("./../../../src/templates/course-page.js" /* webpackChunkName: "component---src-templates-course-page-js" */),
  "component---src-templates-courses-page-js": () => import("./../../../src/templates/courses-page.js" /* webpackChunkName: "component---src-templates-courses-page-js" */),
  "component---src-templates-impressum-page-js": () => import("./../../../src/templates/impressum-page.js" /* webpackChunkName: "component---src-templates-impressum-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-template-tag-page-js": () => import("./../../../src/templates/template-tag-page.js" /* webpackChunkName: "component---src-templates-template-tag-page-js" */)
}

