import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
// import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

// const BREAKPOINTS = {
//   xxs: 0,
//   xs: 300,
//   sm: 600,
//   md: 900,
//   lg: 1200,
//   xl: 1536,
// };

// const breakpointsFull = {
//   breakpoints: createBreakpoints({
//     values: BREAKPOINTS,
//   }),
// };
const defaultTheme = createTheme({});

const theme = createTheme({
  ...defaultTheme,
  // ...breakpointsFull,
  typography: {
    fontFamily: "Ubuntu",
    fontWeightRegular: 400,
    fontWeightMedium: 500,

  },
  palette: {
    primary: {
      // redo video with this color
      // main: teal[600],
      main: "#00897D",
    },
    secondary: {
      main: red.A100,
      //   dark: fade("#F26363"),
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

theme.typography.h1 = {
  fontFamily: "Lovelo",
  // fontSize: "1.7rem",
  //
  // [theme.breakpoints.up("sm")]: {
  //   fontSize: "2rem",
  // },
  // [theme.breakpoints.up("md")]: {
  //   fontSize: "3rem",
  // },
  // [theme.breakpoints.up("lg")]: {
  //   fontSize: "3.5rem",
  // },
};
theme.typography.h2 = {
  fontSize: "1.2rem",
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};
theme.typography.h3 = {
  fontSize: "1.4rem",

  [theme.breakpoints.up("sm")]: {
    fontSize: "1.7rem",
  },
};
theme.typography.h4 = {
  fontSize: "1.2rem",
  fontWeight: 400,



  [theme.breakpoints.down("sm")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1rem",
  },
};
theme.typography.h5 = {
  fontSize: "1rem",
  fontWeight: "normal",

  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
};
theme.typography.h6 = {
  fontSize: "0.9rem",
  fontWeight: "normal",

  [theme.breakpoints.up("sm")]: {
    fontSize: "1.1rem",
  },
};
theme.typography.body1 = {
  fontSize: "0.9rem",
  fontWeight: "normal",

};
theme.typography.p = {
  fontSize: "1rem",
  fontWeight: "normal",

  [theme.breakpoints.up("md")]: {
    fontSize: "1.3rem",
  },
};

export default theme;
